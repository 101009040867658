<template>
    <div>
        <contracts-table-view :type="1" style="margin-bottom:2rem" />

        <banner-offers-view-v2 v-if="user_cloud.total_ths == 0" />
        <showAt breakpoint="mediumAndAbove">
            <offers-view-desktop :showTimer="user_cloud.total_ths == 0 ? false : true" />
        </showAt>
        <showAt breakpoint="small">
            <offers-view-mobile :showTimer="user_cloud.total_ths == 0 ? false : true" />
        </showAt>
    </div>
</template>

<script>

    import ContractsTableView from "@/components/Tables/Contracts/"
    import OffersViewDesktop from "@/views/User/dashboard/views/main/widgets/offers.desktop"
    import OffersViewMobile from "@/views/User/dashboard/views/main/widgets/offers.mobile"
    import BannerOffersViewV2 from "@/views/User/dashboard/views/main/widgets/specialOffer/bannerV2"

    import offers from "@/modules/contents/offers"

    export default {

        data() {
            return {}
        },
        methods: {

        },
        computed: {
            user_cloud() {
                return this.$user.self.stats.cloud;
            }
        },
        components: {
            ContractsTableView,
            OffersViewDesktop,
            OffersViewMobile,
            BannerOffersViewV2
        },
        watch: {

        },
        mounted() {
            if (offers.items.length == 0) {
                offers.get();
            }
        }

    }

</script>